import '../sass/home-header-online.scss';

(() => {
    const $homeHeaderOnlines = document.querySelectorAll('.home-header-online');

    if (!$homeHeaderOnlines) return;

    $homeHeaderOnlines.forEach($homeHeaderOnline => {
        const $countdown = $homeHeaderOnline.querySelector('.home-header-online__countdown');
        const $countdownHrs = $homeHeaderOnline.querySelector('.home-header-online__countdown-hrs');
        const $countdownMins = $homeHeaderOnline.querySelector('.home-header-online__countdown-mins');
        const $countdownSecs = $homeHeaderOnline.querySelector('.home-header-online__countdown-secs');

        const initCountdown = () => {
            if (!$countdown) return;

            const biddingClose = new Date(parseInt($countdown.dataset.biddingClose) * 1000);

            setInterval(() => {
                const now = new Date();

                const diff = new Date(biddingClose.getTime() - now.getTime());

                if ($countdownHrs.innerHTML != diff.getUTCHours()) {
                    $countdownHrs.innerHTML = String(diff.getUTCHours()).padStart(2, '0');
                }

                if ($countdownMins.innerHTML != diff.getUTCMinutes()) {
                    $countdownMins.innerHTML = String(diff.getUTCMinutes()).padStart(2, '0');
                }

                if ($countdownSecs.innerHTML != diff.getUTCSeconds()) {
                    $countdownSecs.innerHTML = String(diff.getUTCSeconds()).padStart(2, '0');
                }
            }, 1000);
        };

        initCountdown();
    });
})();
